<div id="mainWrapper" class="page-wrapper">
    <!-- <div id="mainContent"></div> -->
    <header class="main-nav__header-one">
      <nav class="header-navigation stricky ">
          <div class="container clearfix">
              <!-- Brand and toggle get grouped for better mobile display -->
              <div id="menuToggle" class="main-nav__left">
                  <!-- <a href="#" class="main-nav__search search-popup__toggler"><i
                          class="icon-magnifying-glass"></i></a> -->
                  <a href="#" class="side-menu__toggler">
                      <i class="fa fa-bars"></i>
                  </a>              
              </div>
              <h1 id="mobilePageTitle"></h1>
              <div class="main-nav__main-navigation">
                  <ul class=" main-nav__navigation-box">
                    <li >
                      <div class="topbar-one__middle">
                        <a routerLink="/home" class="main-nav__logo">
                            <img src="./../../../assets/images/resources/logo.png" class="main-logo" alt="Agrola Fruits" />
                        </a>
                    </div>
                  </li>
                      <li class="dropdown">
                          <a class="nav-link text-dark" routerLink="/home" routerLinkActive="active-menu-item">
                           <span class="text-wrap">Home</span>
                          </a>
                      </li>
                      <li class="dropdown">
                        <a class="nav-link text-dark" routerLink="/about" routerLinkActive="active-menu-item">
                          <span class="text-wrap">{{ 'about-us' | translate }}</span>
                        </a>
                    </li>
                    <li class="dropdown">
                        <a class="nav-link text-dark" routerLink="/our-gardens" routerLinkActive="active-menu-item">
                          <span class="text-wrap">{{ 'our-gardens' | translate }}</span>
                        </a>
                    </li>
                    <li class="dropdown">
                      <a class="nav-link text-dark" routerLink="/products" routerLinkActive="active-menu-item">
                          <span class="text-wrap">{{ 'products' | translate }}</span>
                        </a>
                      <ul>
                          <li>
                              <a class="nav-link text-dark" routerLink="/products/cherries" routerLinkActive="active-menu-item">
                                <span class="text-wrap">{{ 'cherries' | translate }}</span>
                                </a>
                          </li>
                          <li> 
                              <a class="nav-link text-dark" routerLink="/products/apricots" routerLinkActive="active-menu-item">
                                <span class="text-wrap">{{ 'apricots' | translate }}</span>
                                </a>
                          </li>
                          <li> 
                              <a class="nav-link text-dark" routerLink="/products/prunes" routerLinkActive="active-menu-item">
                                <span class="text-wrap">{{ 'plums' | translate }}</span>
                                </a>
                          </li>
                      </ul><!-- /.sub-menu -->
                  </li>             
                    <li class="dropdown">
                        <a class="nav-link text-dark" routerLink="/certificates" routerLinkActive="active-menu-item">
                         <span class="text-wrap">{{ 'certificates' | translate }}</span>
                        </a>
                    </li>
                    <li class="dropdown">
                      
                        <a class="nav-link text-dark" routerLink="/careers" routerLinkActive="active-menu-item">
                          <span class="text-wrap">{{ 'careers' | translate }}</span>
                        </a>
                    </li>
                    <li>
                        <a class="nav-link text-dark" routerLink="/contact" routerLinkActive="active-menu-item">
                          <span class="text-wrap">{{ 'contacts' | translate }}</span>
                        </a>
                    </li>
                  </ul>
              </div><!-- /.navbar-collapse 
  
              <div class="main-nav__right">
                  <div class="icon_cart_box">
                      <a href="cart.html">
                          <span class="icon-shopping-cart"></span>
                      </a>
                  </div>
              </div>-->
          </div>
      </nav>
      <select #langSelect (change)="chooseLanguage(langSelect.value) ">
        <option *ngFor="let lang of languages" [value]="lang.code">{{ lang.name }}</option>
      </select>
    </header>
  
    <main>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </main>
  
    <div id="footerWrapper">
        <footer class="site-footer">
          <div class="site-footer_farm_image"><img src="./../../../assets/images/resources/site-footer-farm.png"
                  alt="Farm Image"></div>
          <div class="container">
              <div class="row no-gutters">
                <div class="col-xl-3 col-lg-3 col-md-6">
                  <div class="footer-widget__column footer-widget__contact wow fadeInUp" data-wow-delay="400ms">
                      <div class="footer-widget__title">
                          <h3>{{ 'agrola' | translate }}</h3>
                      </div>
                      <div class="footer-widget_contact">
                          <p>{{ 'adress' | translate }}:</p>
                          <p>{{ 'address1' | translate }} 
                            {{ 'address2' | translate }} 
                            </p>
                          <div class="site-footer__social">
                              <a href="#"><i class="fab fa-facebook-square"></i></a>
                              <a href="#"><i class="fab fa-instagram"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-widget__column footer-widget__contact wow fadeInUp" data-wow-delay="400ms">
                    <div class="footer-widget__title">
                        <h3>{{ 'poveritelnost' | translate }} </h3>
                    </div>
                    <ul class="footer-widget__links-list list-unstyled">
                        <li><a routerLink="/about/personal-data">{{ 'lichnidanni' | translate }}</a></li>
                        <li><a  routerLink="/about/cookies">{{ 'cookies' | translate }}</a></li>
                    </ul>
                </div>
            </div>
    
              <div class="col-xl-3 col-lg-3 col-md-6">
                <div class="footer-widget__column footer-widget__contact wow fadeInUp" data-wow-delay="400ms">
                    <div class="footer-widget__title">
                        <h3>{{ 'contacts-bottom' | translate }}</h3>
                    </div>
                    <div class="footer-widget_contact">
                        <a href="mailto:info@agrolafruits.com">info@agrolafruits.com</a><br>
                        <a href="tel:+359886424243">0886424243</a>
                    </div>
                </div>
            </div>
    
                  <div class="col-xl-3 col-lg-3 col-md-6">
                      <div class="footer-widget__column footer-widget__about wow fadeInUp" data-wow-delay="100ms">
                          <div class="footer-widget__title">
                              <h3>{{ 'working-time' | translate }}</h3>
                          </div>
                          <div class="footer-widget_about_text">
                              <p>{{ 'mon-fri' | translate }}<br>
                                {{ 'worktime-time' | translate }} </p>
                          </div>
                      </div>
                  </div>
              
                
              </div>
          </div>
        </footer>
    
        <div class="site-footer_bottom">
          <div class="container">
              <div class="site-footer_bottom_copyright">
                  <p>copyright 2021 by veleff</p>
              </div>
          </div>
        </div>
      </div>
    </div>
  
  <!-- Mobile menu -->
  <div class="side-menu__block">
      <div class="side-menu__block-overlay custom-cursor__overlay">
          <div class="cursor"></div>
          <div class="cursor-follower"></div>
      </div><!-- /.side-menu__block-overlay -->
      <div class="side-menu__block-inner ">
          <div class="side-menu__top justify-content-end">
              <a href="#" class="side-menu__toggler side-menu__close-btn"><img
                      src="../../../../assets/images/shapes/close-1-1.png" alt=""></a>
          </div><!-- /.side-menu__top -->
  
          <nav class="mobile-nav__container">
              <!-- content is loading via js -->
          </nav>
  
          <div class="side-menu__sep"></div><!-- /.side-menu__sep -->
  
          <div class="side-menu__content">
              <p><a href="mailto:info@agrolafruits.com">info@agrolafruits.com</a> <br> <a href="tel:+359886424243">0886424243</a></p>
              <div class="side-menu__social">
                  <a href="#"><i class="fab fa-facebook-square"></i></a>
                  <a href="#"><i class="fab fa-instagram"></i></a>
              </div>
          </div>
      </div>
  </div>