import { Component } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'agrola-fruits';
  version = 'Angular version 12.2.12';
  translateService : TranslateService;

  certificatesTitle = "test";
  aboutusTitle = "test";
  ourgardensTitle = "test";
  productsTitle = "test";
  cherriesTitle = "test";
  apricotsTitle = "test";
  plumsTitle = "test";
  careersTitle = "test";
  contactsTitle = "test";
  languagesTitle = "test";
  languageCodeBg = "bg"
  languageCodeEn = "en"

  //selectedLanguage = "bg";

  public languages = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "Български",
      code: "bg",
    },
  ];

  constructor(@Inject(PLATFORM_ID) private platformId: object,  private metaTagService: Meta,  private translate: TranslateService) {

    if (isPlatformBrowser(this.platformId)) {

      translate.addLangs(["en", "bg"]);

      var language = localStorage.getItem("language");

      if (!language) {
        const browserLang = translate.getBrowserLang();
        translate.use(browserLang.match(/en|bg/) ? browserLang : "bg");

        translate.currentLang = browserLang;

        localStorage.setItem("language", browserLang);
      } else {
        translate.use(language);
        translate.currentLang = language;
      }
    }
  }

  chooseLanguage(language: string) { 
    this.translate.use(language);
    this.translate.currentLang = language;
    localStorage.setItem("language", language);
   // this.selectedLanguage = language;
  }

  prepareTranslations()
  {
    this.certificatesTitle = this.translate.instant("certificates");
    this.aboutusTitle = this.translate.instant("about-us");
    this.ourgardensTitle = this.translate.instant("our-gardens");
    this.productsTitle = this.translate.instant("products");
    this.cherriesTitle = this.translate.instant("cherries");
    this.apricotsTitle = this.translate.instant("apricots");
    this.plumsTitle = this.translate.instant("plums");
    this.careersTitle = this.translate.instant("careers");
    this.contactsTitle = this.translate.instant("contacts");
    this.languagesTitle = this.translate.instant("languages");
  }

  ngOnInit(): void {
   // this.prepareTranslations();
    this.loadScript('assets/js/theme.js');
    
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Агрола, Фрутс, сливи, сини сливи, кайсии, хладилник, хладилни камери, завод, ферма, ниви, вишни' },
      { name: 'description', content: 'АГРОЛА ФРУТС ООД работи в областта на отглеждане, преработка и търговия на замразени органични плодове' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'veleff' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2021-12-13', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
  }

  loadScript(name: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = name;
      s.async = false;
      document.getElementsByTagName('head')[0].appendChild(s);
    }
  }
}
