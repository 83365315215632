
    <!--<div class="preloader">
      <img src="./../../../assets/images/loader.png" class="preloader__image" alt="">
  </div> /.preloader -->

  <div class="page-wrapper">

      <section class="about_one">
          <div class="container">
              <div class="row">
                  <div class="col-xl-6 col-lg-6">
                      <div class="about1_img">
                          <div class="about1_shape_1"></div>
                          <img src="./../../../assets/images/service/home1.png" alt="About-Img">
                          <div class="about1_icon-box">
                              <div class="circle">
                                  <span class="icon-focus"></span>
                              </div>
                          </div>
                          <div class="about_img_2">
                              <img src="./../../../assets/images/service/home2.png" alt="">
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                      <div class="block-title text-left">
                          <p>{{ 'home-1' | translate }}</p>
                          <h3>{{ 'home-2' | translate }}</h3>
                          <div class="leaf">
                              <img src="./../../../assets/images/resources/leaf.png" alt="">
                          </div>
                      </div>
                      <div class="about_content">
                          <div class="text">
                              <p>{{ 'home-3' | translate }}</p>
                          </div>
                          <div class="about1_icon_wrap">
                              <div class="about1_icon_single">
                                  <div class="about1_icon">
                                      <span class="icon-harvest"></span>
                                  </div>
                                  <p>{{ 'home-4' | translate }}</p>
                              </div>
                              <div class="about1_icon_single">
                                  <div class="about1_icon">
                                      <span class="icon-temperature"></span>
                                  </div>
                                  <p>{{ 'home-5' | translate }}</p>
                              </div>
                          </div>
                          <div class="about1__button-block">
                              <a routerLink="/about" class="thm-btn about_one__btn">{{ 'home-6' | translate }}</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="service_one">
          <div class="container">
              <div class="block-title text-center">
                  <h3>{{ 'home-7' | translate }}</h3>
                  <div class="leaf">
                      <img src="./../../../assets/images/resources/leaf.png" alt="">
                  </div>
              </div>
              <div class="row">
                  <div class="col-xl-3 col-lg-3 col-md-6">
                      <div class="service_1_single wow fadeInUp">
                          <div class="content">
                              <h3>{{ 'about-us' | translate }}</h3>
                              <p>{{ 'home-8' | translate }}</p>
                          </div>
                          <div class="service_1_img">
                              <div class="hover_box">
                                  <a routerLink="/about"><span class="icon-left-arrow"></span></a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6">
                      <div class="service_1_single wow fadeInUp" data-wow-delay="300ms">
                          <div class="content">
                              <h3>{{ 'home-9' | translate }}</h3>
                              <p>{{ 'home-10' | translate }}</p>
                          </div>
                          <div class="service_1_img">
                              <div class="hover_box">
                                  <a routerLink="/our-gardens" ><span class="icon-left-arrow"></span></a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6">
                      <div class="service_1_single wow fadeInUp" data-wow-delay="600ms">
                          <div class="content">
                              <h3>{{ 'certificates' | translate }}</h3>
                              <p>{{ 'home-11' | translate }}</p>
                          </div>
                          <div class="service_1_img">
                              <div class="hover_box">
                                  <a routerLink="/certificates"><span class="icon-left-arrow"></span></a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-3 col-lg-3 col-md-6">
                      <div class="service_1_single wow fadeInUp" data-wow-delay="900ms">
                          <div class="content">
                              <h3>{{ 'contacts' | translate }}</h3>
                              <p>{{ 'home-12' | translate }}</p>
                          </div>
                          <div class="service_1_img">
                              <div class="hover_box">
                                  <a routerLink="/contact"><span class="icon-left-arrow"></span></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="recent-project">
          <div class="container">
              <div class="block-title text-center">
                  <p>{{ 'home-13' | translate }}</p>
                  <h3>{{ 'products' | translate }}</h3>
                  <div class="leaf">
                      <img src="./../../../assets/images/resources/leaf.png" alt="">
                  </div>
              </div>
              <div class="row">
                  <div class="col-xl-4 col-lg-4">
                      <div class="recent_project_single wow fadeInUp" data-wow-delay="300ms">
                          <div class="project_img_box">
                              <img src="./../../../assets/images/project/pr2.jpg" alt="Cherries">
                              <div class="project_content">
                                  <h3>{{ 'cherries' | translate }}</h3>
                              </div>
                              <div class="hover_box">
                                  <a routerLink="/products/cherries"><span class="icon-left-arrow"></span></a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-4">
                      <div class="recent_project_single wow fadeInUp" data-wow-delay="600ms">
                          <div class="project_img_box">
                              <img src="./../../../assets/images/project/pr1.jpg" alt="apricots">
                              <div class="project_content">
                                  <h3>{{ 'apricots' | translate }}</h3>
                              </div>
                              <div class="hover_box">
                                  <a routerLink="/products/apricots"><span class="icon-left-arrow"></span></a>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 wow fadeInUp" data-wow-delay="900ms">
                      <div class="recent_project_single">
                          <div class="project_img_box">
                              <img src="./../../../assets/images/project/pr3.jpg" alt="plums">
                              <div class="project_content">
                                  <h3>{{ 'plums' | translate }}</h3>
                              </div>
                              <div class="hover_box">
                                  <a routerLink="/products/prunes"><span class="icon-left-arrow"></span></a>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>

      <section class="product-one">
          <div class="container">
              <div class="row">
                  <div class="col-xl-6 col-lg-6">
                      <div class="product_img">
                          <img src="./../../../assets/images/resources/pr1.jpg" alt="frozen">

                      </div>
                  </div>
                  <div class="col-xl-6 col-lg-6">
                      <div class="growing_product">
                          <div class="block-title text-left">
                              <p>{{ 'home-14' | translate }}</p>
                              <h3>{{ 'home-15' | translate }}</h3>
                              <div class="leaf">
                                  <img src="./../../../assets/images/resources/leaf.png" alt="">
                              </div>
                          </div>
                          <div class="growing_product_text">
                              <p>{{ 'home-16' | translate }}</p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>


    <section>
    <div class="container">
      <div class="col-xl-12 col-lg-12">
        <div class="">
          <div class="growing_product_text">

            <div class="viewer-wrapper">
              <div #container class="document-container"></div>
            </div>
                               </div>
        </div>
      </div>
      <div class="col-xl-12 col-lg-12">
        <div class="">
          <div class="growing_product_text">

            <div class="viewer-wrapper">
              <div #container2 class="document-container"></div>
            </div>
                               </div>
        </div>
      </div>
  </div>
</section>

</div><!-- /.page-wrapper -->


  <a href="#" data-target="html" class="scroll-to-target scroll-to-top"><i class="fa fa-angle-up"></i></a>
