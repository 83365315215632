import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './modules/general/home/home.component';
import { NotFoundComponent } from './modules/general/not-found/not-found.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/general/contact/contact.module')
      .then(mod => mod.ContactModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./modules/general/about/about.module')
      .then(mod => mod.AboutModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./modules/general/careers/careers.module')
      .then(mod => mod.CareersModule)
  },
  {
    path: 'certificates',
    loadChildren: () => import('./modules/general/certificates/certificates.module')
      .then(mod => mod.CertificatesModule)
  },
  {
    path: 'our-gardens',
    loadChildren: () => import('./modules/general/gardens/gardens.module')
      .then(mod => mod.GardensModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/general/products/products.module')
      .then(mod => mod.ProductsModule)
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }