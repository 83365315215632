<section class="about_one">
    <div class="container">
 
	   <div class="block-title text-center">
        <h3>Ресурсът не е намерен (Error 404)</h3>
        <div class="leaf">
            <img src="./../../../assets/images/resources/leaf.png" alt="">
        </div>
    </div>
    </div>
</section>