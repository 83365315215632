import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import * as docx from 'docx-preview';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})


export class HomeComponent implements OnInit {

  //@ViewChild('viewer') viewer!: ElementRef;
  @ViewChild('container') container!: ElementRef;
  @ViewChild('container2') container2!: ElementRef;

  name = environment.application.name;
  angular = environment.application.angular;
  bootstrap = environment.application.bootstrap;
  fontawesome = environment.application.fontawesome;

  features: any;

  constructor(@Inject(PLATFORM_ID) private platformId: object, private http: HttpClient) {
    this.features =
      [
        {
          name: 'Bootstrap',
          description: 'Bootstrap Prototype Description',
          icon: 'fab fa-bootstrap',
          link: 'bootstrap'
        },
        {
          name: 'Reactive Form',
          description: 'Reactive Form Description',
          icon: 'fab fa-bootstrap',
          link: 'reactive-form'
        },
        {
          name: 'Services',
          description: 'Services Description',
          icon: 'fab fa-bootstrap',
          link: 'services'
        },
        {
          name: 'Components',
          description: 'Components Description',
          icon: 'fab fa-bootstrap',
          link: 'components'
        },
        {
          name: 'Template Driven Forms',
          description: 'Template Driven Forms Description',
          icon: 'fab fa-bootstrap',
          link: 'template-driven-forms'
        },
      ];

  }

  ngOnInit(): void {
    this.loadScript('assets/params/js/index.js');

    this.loadDocument('bilbord.docx');
    this.loadDocument2('bilmord.docx');
/*     WebViewer({
      path: '../assets/docs',
      initialDoc: 'bilbord.docx',
    }, this.viewer.nativeElement); */
  }

  loadDocument(filename: string) {
    this.http.get(`assets/docs/${filename}`, {
      responseType: 'arraybuffer'
    }).subscribe(async (data) => {
      try {
        const documentBlob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });

        await docx.renderAsync(documentBlob,
          this.container.nativeElement,
          null,
          {
            className: 'docx',
            inWrapper: true,
            breakPages: true,
            useBase64URL: true,
            renderHeaders: true,
            renderFooters: true,
            renderFootnotes: true,
            renderEndnotes: true,
            ignoreLastRenderedPageBreak: false,
            ignoreFonts: false,
            ignoreHeight: false,
            ignoreWidth: false,
            trimXmlDeclaration: true,
            experimental: false,
            debug: false
          }
        );
      } catch (error) {
        console.error('Error rendering document:', error);
      }
    });
  }

  loadDocument2(filename: string) {
    this.http.get(`assets/docs/${filename}`, {
      responseType: 'arraybuffer'
    }).subscribe(async (data) => {
      try {
        const documentBlob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        });

        await docx.renderAsync(documentBlob,
          this.container2.nativeElement,
          null,
          {
            className: 'docx',
            inWrapper: true,
            breakPages: true,
            useBase64URL: true,
            renderHeaders: true,
            renderFooters: true,
            renderFootnotes: true,
            renderEndnotes: true,
            ignoreLastRenderedPageBreak: false,
            ignoreFonts: false,
            ignoreHeight: false,
            ignoreWidth: false,
            trimXmlDeclaration: true,
            experimental: false,
            debug: false
          }
        );
      } catch (error) {
        console.error('Error rendering document:', error);
      }
    });
  }

  loadScript(name: string): void {

    if (isPlatformBrowser(this.platformId)) {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = name;
      s.async = false;
      document.getElementsByTagName('head')[0].appendChild(s);
    }
  }

}
